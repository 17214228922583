<template>
  <div class="networkTest" :class="showNetworkResult?'showNetworkResult':''">
    <div v-if="!showNetworkResult" class="top">
      <img
        class="img networkImg"
        src="../../../assets/images/netWork.png"
        alt=""
      />
      <div class="networt">{{ $t("setUp.netDetection") }}</div>
      <div class="info">
        {{
          !networkLoading ? $t("setUp.pleaseDec") : $t("setUp.detectionServer")
        }}
      </div>
    </div>
    <div v-if="showNetworkResult" class="top sucessBox">
      <!-- <img
        class="img success"
        src="../../../assets/images/icon-success.png"
        alt=""
      /> -->
      <my-icon
        :iconName="statusIconClassName"
        class="success"
        :fontSize="128"
      ></my-icon>

      <div class="networt">
        {{ $t("setUp.networkStatus") }}
        {{ statusTitle }}
      </div>
      <div class="info">{{ $t("setUp.netOk") }}{{ statusTitle }}</div>
    </div>
    <div v-if="showNetworkResult" class="content">
      <div class="item">
        <span class="tit">{{ $t("setUp.networkRight") }}</span>
        <div class="right">
          <!-- {{ localNetworkTime > 0 ? $t("setUp.ok") : $t("setUp.overTime") }} -->
          <span class="normal">{{ $t("setUp.ok") }}</span>
          <!-- <span
            v-if="serverNetworkTime <= 0 || serverNetworkTime >= 300"
            class="abnormal"
            >{{ $t("setUp.overTime") }}</span
          > -->
        </div>
      </div>
      <div class="item">
        <span class="tit">{{ $t("setUp.netConnection") }}</span>
        <div class="right">
          <span v-if="localNetworkTime > 0" class="normal">{{
            $t("setUp.ok")
          }}</span>
          <span v-else class="abnormal">{{ $t("setUp.overTime") }}</span>
        </div>
      </div>
      <div class="item">
        <span class="tit">{{ $t("setUp.serverConnection") }}</span>
        <div class="right">
          <span v-if="localNetworkTime > 0" class="normal">{{
            $t("setUp.ok")
          }}</span>
          <span v-else class="abnormal">{{ $t("setUp.overTime") }}</span>
        </div>
      </div>

      <div class="item">
        <span class="tit">{{ $t("setUp.realmName") }}</span>
        <div class="right">
          <span v-if="localNetworkTime > 0" class="normal">{{
            localNetworkTime + "ms"
          }}</span>
          <span v-else class="error">{{ $t("setUp.abnormal") }}</span>
        </div>
      </div>
      <div class="item">
        <span class="tit">{{ $t("setUp.connectionTime") }}</span>
        <div class="right">
          <!-- {{ localNetworkTime > 0 ? $t("setUp.ok") : $t("setUp.overTime") }} -->
          <span v-if="serverNetworkTime > 0" class="normal">{{
            serverNetworkTime + "ms"
          }}</span>
          <span
            v-if="serverNetworkTime <= 0 || serverNetworkTime >= 300"
            class="error"
            >{{ $t("setUp.abnormal") }}</span
          >
        </div>
      </div>
    </div>

    <div class="button">
      <!-- 开始检测 -->
      <van-button
        v-if="!showNetworkResult"
        size="large"
        :loading="networkLoading"
        class="btn"
        @click="startNetworkDetection"
        color="#1AB370"
        >{{ $t("setUp.start") }}</van-button
      >
      <!-- 重新检测 -->
      <van-button
        v-else
        size="large"
        :loading="networkLoading"
        plain
        class="btn"
        @click="startNetworkDetection"
        color="#1AB370"
        >{{ $t("setUp.againDec") }}</van-button
      >
      <!-- 返回 -->
      <van-button
        size="large"
        class="btn bottomBtn"
        @click="back"
        type="default"
        >{{ $t("common.back") }}</van-button
      >
    </div>
  </div>
</template>

<script>
import { ping } from "@/utils/network";
import { signalUrl } from "@/config/index";
import { NETWORK_TEST_URL, MAX_SHARE_VIDEO_FPS } from "@/constant/index";
export default {
  data() {
    return {
      passwordChecked: true,
      showNetworkResult: false,
      meetChecked: true,
      muteChecked: true,
      localNetworkTime: 0,
      serverNetworkTime: 0,
      networkLoading: false,
    };
  },
  mounted() {},
  watch: {
    currentNetworkProgressValue(value) {
      if (value <= this.networkProgressValue && value > 0) {
        const tips = [
          this.$t("setUp.netConnection"),
          this.$t("setUp.serverConnection"),
          this.$t("setUp.connectionTime"),
        ];
        this.currentNetworkProgressTip =
          this.$t("setUp.detecting") + `${tips[value - 1]}`;
      }
      if (value === this.networkProgressValue) {
        this.showNetworkResult = true;
        this.networkLoading = false;
      }
    },
    serverNetworkTime(value) {
      if (value < 0 || this.localNetworkTime <= 0) {
        //-1 超时
        this.statusIconClassName = "icon-wangluojiance_wuwang_128_hong";
        this.statusTitle = this.$t("setUp.abnormal");
      } else if (value > 0 && value < 100) {
        //正常 良好
        this.statusIconClassName = "icon-wangluojiance_wancheng_128_lv";
        this.statusTitle = this.$t("setUp.ok");
      } else if (value >= 100 && value < 300) {
        //较差
        this.statusIconClassName = "icon-wangluojiance_jiaocha_128_cheng";
        this.statusTitle = this.$t("setUp.poor");
      } else if (value >= 300) {
        //极差
        this.statusIconClassName = "iconwangluojiance_jiaocha_48_cheng";
        this.statusTitle = this.$t("setUp.range");
      }
    },
  },
  methods: {
    meetSetting() {
      this.$router.push("meetSetting");
    },
    go(path) {
      this.$router.push(path);
    },
    //网络检测
    async startNetworkDetection() {
      this.networkLoading = true;
      // 1.网络连接情况
      this.currentNetworkProgressValue = 1;
      const local = await ping(NETWORK_TEST_URL, 0.3);
      this.localNetworkTime = local.pingTime;

      // 2.服务器连接 + 连接耗时
      const server_url =
        "https://" + signalUrl.replace("/signal-server", "") + "/test/test.png";
      this.currentNetworkProgressValue = 2;
      const server = await ping(server_url, 0.3);
      this.serverNetworkTime = server.pingTime;
      setTimeout(() => {
        this.currentNetworkProgressValue = 3;
        this.showNetworkResult = true;
        this.networkLoading = false;
      }, 1000);
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang='less'>
.networkTest {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff;
  .top {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #fff;
    .img {
      width: 128px;
      height: 128px;
    }
    .networkImg {
      margin-top: 120px;
    }
    .success {
      margin-top: 40px;
    }
    .networt {
      font-size: 32px;
      color: #333;
      margin-top: 20px;
    }
    .info {
      font-size: 24px;
      color: #999;
    }
  }
  .sucessBox {
    height: 320px;
  }
  .content {
    width: 100%;
    height: 519px;
    box-sizing: border-box;
    padding: 0 30px;
    font-size: 32px;
    background: #fff;
    margin-top: 16px;
    .item {
      height: 104px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tit {
        color: #333;
      }
      .normal {
        color: #1ab370;
      }
      .abnormal {
        color: #ff4d4f;
      }
    }
  }
  .button {
    width: 100%;
    box-sizing: border-box;
    padding: 0 64px;
    margin-top: 80px;
    .btn {
      height: 88px;
    }
    .bottomBtn {
      margin-top: 32px;
    }
  }
}
.showNetworkResult{
  background:#fafafa ;
}
</style>
